function initialState() {
  return {
    meta: {
      title: "Claims Gate",
      // link: [
      //   {
      //     rel: "icon",
      //     href: require("@/assets/images/favicon.ico")
      //   }
      // ],
      meta: [
        { name: "description", content: "Quick and easy. Register a claim in minutes, entirely online." },
        {
          name: "og:description",
          content: "Quick and easy. Register a claim in minutes, entirely online.",
        },
        {
          name: "twitter:description",
          content: "Quick and easy. Register a claim in minutes, entirely online.",
        },
        { name: "keywords", content: "Claims Gate, ClaimsGate" },
        { name: "author", content: "Claims Gate | Instantly Increase Advertising Return" },
        { name: "og:title", content: "Claims Gate | Instantly Increase Advertising Return" },
        { name: "twitter:title", content: "Claims Gate | Instantly Increase Advertising Return" },
      ],
    },
  };
}
export const state = initialState();

export const getters = {
  getMeta(state) {
    return state.meta;
  },
};

export const mutations = {
  setTitle(state, title) {
    if (title) {
      state.meta.title = title;
    }
  },
  setDescriptions(state, description) {
    if (description) {
      const metaTags = [];
      const tagNames = ["description", "og:description", "twitter:description"];
      tagNames.forEach((name) => {
        metaTags.push({ name: name, content: description });
      });
      state.meta.meta = metaTags;
    }
  },
  setIcon(/* state, iconData */) {
    // Removed for now as causing a bug on /claims where setting to currnet workspace icon, should remain CG icon
    // if (iconData) {
    //   console.log("SETTING ICON", iconData);
    //   state.meta.link[0] = { rel: "icon", href: iconData };
    // }
  },
  reset(state) {
    Object.assign(state, initialState());
  },
  setBuilder(state) {
    state.meta.title = "Builder | Claims Gate";
  },
  setPages(state) {
    state.meta.title = "Pages | Claims Gate";
  },
  setMe(state) {
    state.meta.title = "Me | Claims Gate";
  },
  setSettings(state) {
    state.meta.title = "Settings | Claims Gate";
  },
  setFunnels(state) {
    state.meta.title = "Funnels | Claims Gate";
  },
  setWorkspace(state) {
    state.meta.title = "Workspace | Claims Gate";
  },
  setClaims(state) {
    state.meta.title = "Claims | Claims Gate";
  },
};

export const actions = {
  setTitle({ commit }, title) {
    commit("setTitle", title);
  },
  setDescriptions({ commit }, description) {
    commit("setDescriptions", description);
  },
  setIcon({ commit }, iconData) {
    commit("setIcon", iconData);
  },
  reset({ commit }) {
    commit("reset");
  },
  setBuilder({ commit }) {
    commit("reset");
    commit("setBuilder");
  },
  setPages({ commit }) {
    commit("reset");
    commit("setPages");
  },
  setMe({ commit }) {
    commit("reset");
    commit("setMe");
  },
  setSettings({ commit }) {
    commit("reset");
    commit("setSettings");
  },
  setFunnels({ commit }) {
    commit("reset");
    commit("setFunnels");
  },
  setWorkspace({ commit }) {
    commit("reset");
    commit("setWorkspace");
  },
  setClaims({ commit }) {
    commit("reset");
    commit("setClaims");
  },
};
