import { ThemesService } from "../../helpers/ClaimsGate/themes/ThemesService";
const fontMap = ThemesService.fontFamilyMap();
const fontArray = [...fontMap.sanSerifFontFamilies, ...fontMap.serifFontFamilies].map((font) => {
  return {
    url: font.url,
    fontFamily: font.style["font-family"],
  };
});
export const state = {
  theme: {
    // theme keys here
    name: "",
    primaryColour: "",
    secondaryColour: "",
    size: "",
    squared: false,
    fontFamily: "",
    backgroundColour: "",
    headerFooterColour: "",
    headerFooterTextColour: "",
    textColour: "",
    id: "",
    fontStyles: {},
    primaryStyles: {},
    secondaryStyles: {},
    backgroundStyles: {},
    headerFooterStyles: {},
    headerFooterTextStyles: {},
    textColourStyles: {},
  },
  language: "en",
  loadedFonts: [],
};

export const getters = {
  getTheme(state) {
    return state.theme;
  },

  getLanguage(state) {
    return state.language;
  },
};

export const mutations = {
  setLanguage(state, language) {
    state.language = language;
  },

  setTheme(state, theme) {
    // load appropriate font styles
    state.theme = theme;
  },

  setPrimaryColour(state, primaryColour) {
    state.theme.primaryColour = primaryColour;
  },

  setPrimaryStyles(state, primaryStyles) {
    state.theme.primaryStyles = primaryStyles;
  },

  setTextColour(state, textColour) {
    state.theme.textColour = textColour;
  },

  setTextColourStyles(state, textColourStyles) {
    state.theme.textColourStyles = textColourStyles;
  },

  setHeaderFooterTextColour(state, headerFooterTextColour) {
    state.theme.headerFooterTextColour = headerFooterTextColour;
  },

  setSize(state, size) {
    state.theme.size = size;
  },

  setSquared(state, squared) {
    state.theme.squared = squared;
  },

  addLoadedFont(state, fontStyles) {
    state.loadedFonts.push(fontStyles);
  },
};

export const actions = {
  setLanguage({ commit }, language) {
    console.log(">>> setLanguage", language);
    commit("setLanguage", language);
  },

  async loadTheme({ dispatch }, { themeId }) {
    const themesService = new ThemesService();

    let theme = {};
    if (!themeId) {
      await dispatch("loadClaimsGateTheme");
      return;
    }

    const { data: workspaceTheme } = await themesService.getWorkspaceTheme(themeId);

    if (workspaceTheme) {
      theme = workspaceTheme;
    } else {
      console.log("[themes.js] loading in default claimsgate theme since undefined");
      // await this.loadClaimsGateTheme();
      await dispatch("loadClaimsGateTheme");
      return;
    }

    // Need to set defaults for block props that are now being set by themes
    if (theme?.squared === undefined) {
      theme.squared = false;
    }
    // if (theme?.size === undefined) {
    //   theme.size = "lg";
    // }

    // ? Currently setting size to always be lg as we don't yet support different sizes
    theme.size = "lg";
    await dispatch("setTheme", theme);
  },

  async loadClaimsGateTheme({ state, dispatch }) {
    const themesService = new ThemesService();
    const defaultThemeId = "WPrvkaSO2wNONWFQpoPu";

    let theme = {};
    let workspaceTheme;

    if (state.theme.id !== defaultThemeId) {
      ({ data: workspaceTheme } = await themesService.getWorkspaceTheme(defaultThemeId));
    } else {
      theme = state.theme;
    }

    console.log("[themes.js] ", workspaceTheme);
    if (workspaceTheme) {
      theme = workspaceTheme;
    }

    // Need to set defaults for block props that are now being set by themes
    if (theme?.squared === undefined) {
      theme.squared = false;
    }
    if (theme?.size === undefined) {
      theme.size = "lg";
    }
    await dispatch("setTheme", theme);
  },

  async loadFont({ commit, state }, fontStyles) {
    if (state.loadedFonts.includes(fontStyles)) {
      return;
    }

    const url = fontArray?.find((font) => font?.fontFamily === fontStyles?.["font-family"])?.url;

    if (url) {
      await attachFontToDocument(url);

      commit("addLoadedFont", fontStyles);
    } else {
      console.error(`Failed to get url for font`, { fontStyles });
    }
  },

  loadAllFonts() {
    const urls = fontArray.map((font) => font.url);
    urls.forEach((url) => {
      if (!url) return;
      const selectedFont = document.createElement("style");
      selectedFont.appendChild(
        document.createTextNode(`
          @import url("${url}")
        `)
      );

      document.head.appendChild(selectedFont);
    });
  },

  async setTheme({ commit, dispatch }, theme) {
    const { fontStyles } = theme;

    await dispatch("loadFont", fontStyles);

    commit("setTheme", theme);
  },

  setPrimaryColour({ commit }, primaryColour) {
    commit("setPrimaryColour", primaryColour);
  },

  setPrimaryStyles({ commit }, primaryStyles) {
    commit("setPrimaryStyles", primaryStyles);
  },

  setTextColour({ commit }, textColour) {
    commit("setTextColour", textColour);
  },

  setTextColourStyles({ commit }, textColourStyles) {
    commit("setTextColourStyles", textColourStyles);
  },

  setHeaderFooterTextColour({ commit }, headerFooterTextColour) {
    commit("setHeaderFooterTextColour", headerFooterTextColour);
  },

  setSize({ commit }, size) {
    commit("setSize", size);
  },

  setSquared({ commit }, squared) {
    commit("setSquared", squared);
  },
};
async function attachFontToDocument(url) {
  let selectedFont = document.createElement("style");

  selectedFont.appendChild(document.createTextNode(`@import url("${url}")`));
  console.log("LOADING FONT", url);
  const fontResolver = new Promise((resolve) => {
    selectedFont.addEventListener("load", () => {
      console.log("Event listner on load");
      resolve(true);
    });
  });

  document.head.appendChild(selectedFont);

  await fontResolver;
  console.log("COMPLETED LOADING FONT", url);
}
