import { getFirebaseBackend } from "../../authUtils.js";
// import { getUserHelper } from "../../helpers/ClaimsGate/UserHelper";
import { WorkspaceService } from "@claimsgate/core";

import { ClaimsGateIdsToAppRoutes } from "@/types/services/ClaimsGateApps";

/**
 * User is logged in and selects a workspace
 *  - Navigation links are generated using navigation vue x module
 *  - User refreshes their page
 *  - Navigation links disappear unless they are fetched again (normal behaviour in a Vue file)
 *
 * User refreshes their page and then visits /logout
 */
export const state = {
  navigationLinks: JSON.parse(localStorage.getItem("navigationLinks")) ?? [],
  // navigationLinks: [],
  generalNavigationLinks: [
    { name: "Home", route: "Claims", hovered: false },
    { name: "Me", route: "Me", hovered: false },
  ],
  id: "",
  name: "",
  logo: "",
  footer: "",
  displaySRATag: false,
  allowNameChanges: false,
  allowAddressChanges: false,
  allowContactDetailChanges: false,
  hideTrackPageClaimant: false,
  workspace: {},
};

export const getters = {
  getNavigationLinks(state) {
    return state.navigationLinks;
  },

  getWorkspaceId(state) {
    return state.id;
  },

  getWorkspaceName(state) {
    return state.name;
  },

  getWorkspaceLogo(state) {
    return state.logo;
  },
  getWorkspaceFooter(state) {
    return state.footer;
  },
  getWorkspaceDisplaySRATag(state) {
    return state.displaySRATag;
  },

  getWorkspaceAllowNameChanges(state) {
    return state.allowNameChanges;
  },

  getWorkspaceAllowAddressChanges(state) {
    return state.allowAddressChanges;
  },

  getWorkspaceAllowContactDetailChanges(state) {
    return state.allowContactDetailChanges;
  },

  getWorkspaceAllowClaimDeletion(state) {
    return state.allowClaimDeletion;
  },

  getWorkspaceAllowAccountDeletion(state) {
    return state.allowAccountDeletion;
  },

  getWorkspaceHideTrackPageClaimant(state) {
    return state.hideTrackPageClaimant;
  },

  getWorkspaceAllowCustomPolicy(state) {
    return state.allowCustomPolicy;
  },
};

export const mutations = {
  setWorkspace(
    state,
    {
      id,
      name,
      logo,
      footer,
      displaySRATag,
      allowNameChanges,
      allowAddressChanges,
      allowContactDetailChanges,
      allowClaimDeletion,
      allowAccountDeletion,
      hideTrackPageClaimant,
      allowCustomPolicy,
    }
  ) {
    state.id = id;
    state.name = name;
    state.logo = logo;
    state.footer = footer;
    state.displaySRATag = displaySRATag;
    state.allowNameChanges = allowNameChanges;
    state.allowAddressChanges = allowAddressChanges;
    state.allowContactDetailChanges = allowContactDetailChanges;

    state.allowClaimDeletion = allowClaimDeletion;
    state.allowAccountDeletion = allowAccountDeletion;
    state.hideTrackPageClaimant = hideTrackPageClaimant;

    state.allowCustomPolicy = allowCustomPolicy;

    console.log("Updated workspace", state);
  },

  setFullWorkspace(state, workspace) {
    state.workspace = workspace;
  },

  setNavigationLinks(state, navigationLinks) {
    const allNavigationLinks = navigationLinks.concat(state.generalNavigationLinks);
    state.navigationLinks = allNavigationLinks;
    saveState("navigationLinks", allNavigationLinks);
  },

  hoverLink(state, index) {
    state.navigationLinks[index].hovered = true;
  },

  unhoverLink(state, index) {
    state.navigationLinks[index].hovered = false;
  },
};

export const actions = {
  async setWorkspace({ commit, state }, { workspaceId, useCache }) {
    let workspace;

    // Request to use cache, and workspace has already been retrieved
    if (useCache && Object.keys(state.workspace)?.length > 0 && workspaceId === state.id) {
      return state.workspace;
    }

    if (workspaceId) {
      // Fetch workspaceData
      const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
      ({ data: workspace } = await workspaceService.getWorkspace(workspaceId));
    }

    if (!workspace) {
      workspace = {};
    }

    commit("setFullWorkspace", workspace);

    const workspaceData = {
      id: workspace?.id || null,
      name: workspace?.name || null,
      logo: workspace?.logo || null,
      footer: workspace?.footer || null,
      displaySRATag: workspace?.displaySRATag || null,
      allowNameChanges: workspace?.allowNameChanges ?? true,
      allowAddressChanges: workspace?.allowAddressChanges ?? true,
      allowContactDetailChanges: workspace?.allowContactDetailChanges ?? true,
      allowClaimDeletion: workspace?.allowClaimDeletion ?? true,
      allowAccountDeletion: workspace?.allowAccountDeletion ?? true,
      hideTrackPageClaimant: workspace?.hideTrackPageClaimant ?? false,
      allowCustomPolicy: workspace?.allowCustomPolicy ?? false,
    };

    commit("setWorkspace", workspaceData);

    return workspace;
  },

  clearWorkspace({ commit }) {
    commit("setWorkspace", {
      id: "",
      name: "",
      logo: "",
      footer: "",
      displaySRATag: false,
      allowNameChanges: false,
      allowAddressChanges: false,
      allowContactDetailChanges: false,
      hideTrackPageClaimant: false,
    });
  },

  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("setNavigationLinks", { userApps: [], workspaceName: "Claims Gate" });
  },

  clearNavigationData({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("setNavigationLinks", []);
  },

  setNavigationLinks({ commit }, { userApps, workspaceName }) {
    const navigationLinks = [];

    navigationLinks.push({ name: workspaceName ?? "Create Workspace", route: "Workspace", hovered: false });

    userApps.forEach((app) => {
      if (ClaimsGateIdsToAppRoutes[app]) {
        navigationLinks.push({
          name: ClaimsGateIdsToAppRoutes[app].name,
          route: ClaimsGateIdsToAppRoutes[app].route,
          hovered: false,
        });
      }
    });
    // navigationLinks.push({ name: "Funnels", route: "Funnels", hovered: false });

    commit("setNavigationLinks", navigationLinks);
  },

  getNavigationWorkspaceId({ state }) {
    return state.id;
  },

  hoverLink({ commit }, index) {
    commit("hoverLink", index);
  },

  unhoverLink({ commit }, index) {
    commit("unhoverLink", index);
  },
};

// saving to localStorage functions

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
