export default {
  signInToAccess: "Sign in to access your account with",
  signInMethod: "Sign in method",
  selectSignInMethod: "Select a sign in method",
  continue: "Continue",
  welcomeBack: "Welcome Back!",
  emailPlaceholder: "Email",
  emailLabel: "Email",
  phonePlaceholder: "Phone",
  phoneLabel: "Phone",
  enterYourPassword: "Enter your password",
  enterPasswordLink: "Enter the password linked to",
  back: "Back",
  passwordPlaceholder: "Password",
  passwordLabel: "Password",
  forgotPassword: "Forgot password?",
  signIn: "Sign in",
  showPassword: "Show password",
  createPasswordHeading: "Create Your Password",
  forgotPasswordHeading: "Forgot Password",
  createPasswordSupporting: "Please create a new password for your account with",
  confirmLabel: "Confirm",
  confirmPlaceholder: "Confirm",
  incorrectPassword: "Incorrect password. Please try again.",
  otpModalHeading: "Confirm the code",
  otpModalSupporting: "Enter the code sent to:",
  otpModalInfo:
    "You may need to check your spam folder. If you are having issues receiving the code, please go back and try using an alternative login method",
  otpModalCodeInvalid: "The code you entered is invalid",
};
