import { getFirebaseBackend } from "../../authUtils.js";
import { onCallGateway } from "../../helpers/ClaimsGate/onCallGateway.ts";
export const state = {
  currentUser: tryParseJSON(localStorage.getItem("auth.currentUser")),
  authToken: tryParseJSON(localStorage.getItem("auth.authToken")) ?? { token: "", createdAt: null },
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState("auth.currentUser", newValue);
  },

  setAuthToken(state, authToken) {
    state.authToken = authToken;
    saveState("auth.authToken", authToken);
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },

  getAuthToken(state) {
    return state.authToken;
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

  async customTokenLogin({ commit }, { token } = {}) {
    try {
      const res = await getFirebaseBackend()
        .customTokenLogin(token)
        .then(async (response) => {
          var user = response;
          var x = await user.getIdTokenResult();
          user = JSON.parse(JSON.stringify(user));
          user.claims = x.claims;
          commit("SET_CURRENT_USER", user);
          return user;
        })
        .catch(() => {
          return false;
        });

      return res;

      //if (getters.loggedIn) return dispatch('validate')
    } catch (exception) {
      return false;
    }
  },

  anonUpgrade({ commit }, { email, password } = {}) {
    //if (getters.loggedIn){ return dispatch('validate'); }
    return new Promise((resolve, reject) => {
      window.console.log("Calling Upgrade:", email, password);
      return getFirebaseBackend()
        .upgradeAnonToUser(email, password)
        .then(async (response) => {
          window.console.log(response);
          var user = response;

          var x = await user.getIdTokenResult();
          user.claims = x.claims;
          commit("SET_CURRENT_USER", JSON.parse(JSON.stringify(user)));
          return resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  anonLogIn({ commit, dispatch, getters }) {
    if (getters.loggedIn) return dispatch("validate");
    return getFirebaseBackend()
      .signInAnonymously()
      .then(async (response) => {
        var user = response;
        var x = await user.getIdTokenResult();
        user = JSON.parse(JSON.stringify(user));
        user.claims = x.claims;
        commit("SET_CURRENT_USER", user);
        return user;
      });
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .loginUser(email, password)
      .then(async (response) => {
        var user = response;
        var x = await user.getIdTokenResult();
        user = JSON.parse(JSON.stringify(user));
        user.claims = x.claims;
        commit("SET_CURRENT_USER", user);
        return user;
      });
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("SET_CURRENT_USER", null);
    commit("setAuthToken", null);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      getFirebaseBackend()
        .logout()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  },

  // register the user
  register({ commit, dispatch, getters }, { email, password, userdata } = {}) {
    if (getters.loggedIn) {
      window.console.log("Issue has been found?!");
      return dispatch("validate");
    }
    window.console.log("Calling Register!");
    return getFirebaseBackend()
      .registerUser(email, password, userdata)
      .then(async (response) => {
        window.console.log(response);
        var user = response;
        var x = await user.getIdTokenResult();
        user.claims = x.claims;
        commit("SET_CURRENT_USER", JSON.parse(JSON.stringify(user)));
        return user;
      });
  },

  // eslint-disable-next-line no-unused-vars
  setUser({ commit, dispatch, getters }, { user } = {}) {
    if (getters.loggedIn) return dispatch("validate");
    commit("SET_CURRENT_USER", JSON.parse(JSON.stringify(user)));
    return;
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response) => {
        const message = response.data;
        return message;
      });
  },
  // eslint-disable-next-line no-unused-vars
  emailLogin({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");
    return getFirebaseBackend()
      .sendEmailLogin(email)
      .then((response) => {
        //const message = response.data
        return response;
      });
  },
  // eslint-disable-next-line no-unused-vars
  tryEmailSignIn({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");
    return getFirebaseBackend()
      .tryEmailSignInLink()
      .then((user) => {
        commit("SET_CURRENT_USER", JSON.parse(JSON.stringify(user)));
        return user;
      });
  },
  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) {
      return Promise.resolve(null);
    } // this line does nothing believe it or not lmao
    const user = getFirebaseBackend().getAuthenticatedUser();
    commit("SET_CURRENT_USER", JSON.parse(JSON.stringify(user)));
    return user;
  },

  // eslint-disable-next-line no-unused-vars
  refreshClaims({ commit, state }) {
    return new Promise((resolve, reject) => {
      try {
        getFirebaseBackend()
          .firebaseAuth()
          .onAuthStateChanged(async (user) => {
            var x = await user.getIdTokenResult(true);
            user = JSON.parse(JSON.stringify(user));
            user.claims = x.claims;
            commit("SET_CURRENT_USER", JSON.parse(JSON.stringify(user)));
            return resolve(user);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  async generateAuthToken({ commit }) {
    const { data: authToken } = await onCallGateway({ functionName: "generateCustomToken" });
    if (authToken) {
      commit("setAuthToken", { token: authToken, createdAt: Math.round(new Date().getTime() / 1000) });
    }
  },

  setAuthToken({ commit }, authToken) {
    commit("setAuthToken", authToken);
  },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

export function tryParseJSON(text) {
  try {
    return JSON.parse(text);
    // eslint-disable-next-line no-empty
  } catch (e) {
    return null;
  }
}
