/**
 * Form.js is a Vue X state manager which helps centralize key data points between
 * the form and its child components
 */
export const state = {
  loaders: {
    next: false,
    previous: false,
    nextRepeatablePage: false,
    previousRepeatablePage: false,
  },
  funnelMeta: {},
  fileUploadProgress: [],
};

export const getters = {
  /**
   * Returns each of the loaders
   */
  getLoaders(state) {
    return state.loaders;
  },
  getFunnelMeta(state) {
    return state.funnelMeta;
  },

  getFileUploadProgress(state) {
    return state.fileUploadProgress;
  },

  getFileUploadProgressByName: (state) => (fileName) => {
    return state.fileUploadProgress.find((progress) => progress.fileName === fileName);
  },
  filesAreUploading(state) {
    return state.fileUploadProgress.some((fileProgress) => fileProgress.progress > 0);
  },
};

/**
 * Defines the mutations which mutate the Vue X state
 */
export const mutations = {
  toggleLoader(state, loaderName) {
    state.loaders[loaderName] = !state.loaders[loaderName];
  },

  setLoader(state, loaderDetails) {
    const { name, value } = loaderDetails;
    state.loaders[name] = value;
  },

  initalizeLoaders(state) {
    Object.keys(state.loaders).forEach((loaderName) => {
      state[loaderName] = false;
    });
  },

  setFunnelMeta(state, funnelMeta) {
    state.funnelMeta = funnelMeta;
  },
  resetFileUploadProgress(state) {
    state.fileUploadProgress = [];
  },
  setFileUploadProgress(state, value) {
    const existingFileIndex = state.fileUploadProgress.findIndex((progress) => progress.fileName === value.fileName);
    if (existingFileIndex > -1) {
      state.fileUploadProgress.splice(
        state.fileUploadProgress.findIndex((progress) => progress.fileName === value.fileName),
        1,
        value
      );
    } else {
      state.fileUploadProgress.push(value);
    }
  },
};
/**
 * Defines the actiosn which can occur to trigger a mutation
 */
export const actions = {
  toggleLoader({ commit }, loaderName) {
    commit("toggleLoader", loaderName);
  },

  setLoader({ commit }, loaderDetails) {
    commit("setLoader", loaderDetails);
  },
  initalize({ commit }) {
    commit("initalizeLoaders");
  },

  resetFileUploadProgress({ commit }) {
    commit("resetFileUploadProgress");
  },

  setFunnelMeta({ commit }, funnelMeta) {
    console.log("Set funnel meta called", funnelMeta);
    commit("setFunnelMeta", funnelMeta);
  },
  setFileUploadProgress({ commit }, value) {
    commit("setFileUploadProgress", value);
  },
};
