import store from "@/state/store";
// import { permionisssComputed } from "../state/helpers";

// Services
import { WorkspaceService } from "@claimsgate/core";
import { getFirebaseBackend } from "./authUtils";
import FormComponent from "@/components/claimant/form/form.vue";

// Functions
import { getClaimsGateDomain } from "@/helpers/ClaimsGate/RedirectHelper";

const defaultRoute = [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import(/* webpackChunkName: "claims" */ "@/components/claimant/home/claims.vue"),
  },
];

const authRoutes = [
  {
    path: "/login/:workspaceId?",
    name: "login",
    component: () => import(/* webpackChunkName: "auth" */ "@/components/shared/auth/login/login.vue"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (localStorage.getItem("userDocument")?.email || localStorage.getItem("userDocument")?.phoneNumber) {
          if (routeTo?.params?.workspaceId) {
            next({ name: "WorkspaceClaims", params: { workspaceId: routeTo.params.workspaceId } });
          } else {
            next({ name: "default" });
          }
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/logout/:workspaceId?",
    name: "logout",
    meta: {
      //authRequired: true,
      public: true,
      async beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          await store.dispatch("permissions/clearAllPermissionData");
          await store.dispatch("navigation/clearNavigationData");
          await store.dispatch("auth/logOut");
        }
        // Navigate back to previous page, or home as a fallback
        if (routeTo?.params?.workspaceId) {
          next({ name: "login", params: { workspaceId: routeTo.params.workspaceId } });
        } else if (window.location.host !== getClaimsGateDomain()) {
          const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
          const { data: workspaceId } = await workspaceService.getWorkspaceIdFromSubdomain(window.location.host);
          if (workspaceId) {
            return next({
              name: "login",
              params: { workspaceId: workspaceId },
            });
          } else {
            next({ name: "login" });
          }
        } else {
          next({ name: "login" });
        }
      },
    },
  },
  {
    path: "/continue/:regNum?",
    name: "continue",
    meta: { isPublic: true },
    component: () => import(/* webpackChunkName: "auth" */ "@/components/shared/continue/continue.vue"),
  },
];

const workspaceRoutes = [
  {
    path: "/apps/workspace",
    name: "WorkspaceSelect",
    meta: { authRequired: true },
    component: () =>
      import(
        /* webpackChunkName: "workspace" */ "@/components/workspace/workspaces/workspaceSelect/workspaceSelect.vue"
      ),
  },
  {
    path: "/apps/welcome",
    name: "Welcome",

    component: () => import(/* webpackChunkName: "workspace" */ "@/components/workspace/onboarding/onboarding.vue"),
  },
  {
    path: "/apps/workspace/:workspaceId",
    name: "Workspace",
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: "workspace" */ "@/components/workspace/workspaces/workspace.vue"),
  },
  {
    path: "/apps/pages/:funnelId",
    name: "Pages",
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: "workspace" */ "@/components/workspace/crm/pages/pages.vue"),
  },
  {
    path: "/apps/builder/:funnelId?/:pageId?",
    name: "Builder",
    meta: { authRequired: true },
    component: () =>
      import(/* webpackChunkName: "workspace-builder" */ "@/components/workspace/crm/builder/builder.vue"),
  },
  {
    path: "/admin/login",
    name: "admin_login",
    meta: { authRequired: true, adminRequired: true },
    component: () => import(/* webpackChunkName: "claims" */ "@/components/shared/admin/forcelogin.vue"),
  },
  {
    path: "/files/:fileId/:options?",
    name: "File",
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: "claims" */ "@/components/shared/file/file.vue"),
  },
];

const claimRoutes = [
  {
    path: "/claims",
    name: "Claims",
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: "claims" */ "@/components/claimant/home/claims.vue"),
  },
  {
    path: "/claims/:workspaceId",
    name: "WorkspaceClaims",
    meta: { authRequired: true },
    component: () =>
      import(/* webpackChunkName: "claims" */ "@/components/claimant/workspaceClaims/workspaceClaims.vue"),
  },
  {
    path: "/track/:claimId?",
    name: "Track",
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: "claims" */ "@/components/claimant/track/track.vue"),
  },
];

const userRoutes = [
  {
    path: "/me/:workspaceId?",
    name: "Me",
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: "user" */ "@/components/shared/user/myDetails/myDetails.vue"),
  },
  {
    path: "/settings/:workspaceId?",
    name: "Settings",
    meta: { authRequired: true },
    component: () => import(/* webpackChunkName: "user" */ "@/components/shared/user/settings/settings.vue"),
  },
  {
    path: "/profile",
    redirect: { name: "Me" },
  },
];

const publicRoutes = [
  {
    path: "*",
    name: "404page",
    meta: { public: true },
    component: () => import(/* webpackChunkName: "error" */ "@/components/shared/404page/404page.vue"),
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    meta: { public: true },
    component: () => import(/* webpackChunkName: "legal" */ "@/components/shared/legal/privacy_policy.vue"),
  },
  {
    path: "/legal/:document?",
    name: "legal",
    meta: { public: true },
    component: () => import(/* webpackChunkName: "legal" */ "@/components/shared/legal/legal.vue"),
  },
];

const formRoutes = [
  {
    path: "/form/:funnelId/:pageId?/:claimId?",
    name: "form",
    meta: { authRequired: false },
    component: FormComponent,
  },
];

// Special redirects
const redirectRoutes = [
  {
    path: "/welcome/mercedes",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/vauxhall",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/volkswagen",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/diesel",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/enter_reg",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/enter_reg/:regNum",
    redirect: "/form/ja6Zeq12PfLqWlwOdgc3/NoL5sqm7ipjaERNIrgn9",
  },
  {
    path: "/welcome/tesco",
    redirect: "/form/AYGlaP5d05oFw8vJIhPv/06jbveYFsAN3ycVhJPg5",
  },
];

// Combine all routes
export default [
  ...defaultRoute,
  ...authRoutes,
  ...workspaceRoutes,
  ...claimRoutes,
  ...userRoutes,
  ...publicRoutes,
  ...formRoutes,
  ...redirectRoutes,
];
