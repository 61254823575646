// Top level redirects for SPA
if (window.location.hostname === "api.claimsgate.co.uk") {
  window.location.pathname = "v1/docs/";
}

// Critical imports
import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueRouter from "vue-router";
import router from "./router.js";
import "@/design/index.scss";
import store from "@/state/store";
import App from "./components/shared/app/App.vue";
import { i18n } from "./i18n";
import InfoModal from "@/components/shared/global/informationModal/informationModalPlugin.js";
import { initFirebaseBackend, getFirebaseBackend } from "./authUtils";
import { WorkspaceReferralService } from "@/helpers/ClaimsGate/referral/WorkspaceReferralService";

// Fontawesome icons
// import { dom } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// eslint-disable-next-line
// import icons from "./design/custom/plugins/icons/iconConfig";

require("@/additionalStyles.css");

// Register Font Awesome component
// Vue.component("font-awesome-icon", FontAwesomeIcon);
// dom.watch();

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

// Initialize Firebase immediately - mission critical
if (getFirebaseBackend() == null) {
  initFirebaseBackend(firebaseConfig);
}

// Initialize critical Vue plugins
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(InfoModal);
Vue.config.productionTip = false;

// Initialize WorkspaceReferralService immediately - important
const workspaceReferralService = new WorkspaceReferralService();
const urlParams = new URLSearchParams(window.location.search);
workspaceReferralService.storeReferrerFromLocalStorage(localStorage, urlParams).then(() => {
  window.console.log("Successfully stored referral from URL");
});

window.originalLocation =
  document.location.protocol +
  "//" +
  document.location.hostname +
  document.location.pathname +
  document.location.search;
// ga_includes

// Create and mount the app immediately
const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// Initialize non-critical services after app mount
const initNonCriticalServices = async () => {
  // Initialize Google Analytics
  if (process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
    const VueGtag = await import("vue-gtag");
    const ga_config = { id: "G-8D8120WRXQ" };
    Vue.use(
      VueGtag.default,
      {
        config: ga_config,
        pageTrackerTemplate(to) {
          window.console.log(to);
          return {
            page_location: window.originalLocation,
            page_path: to.path + window.location.search,
          };
        },
      },
      router
    );
    console.log("Google Analytics initialized");
  }

  // Initialize Sentry
  const Sentry = await import("@sentry/vue");

  if (process.env.VUE_APP_RUNNING_FROM_EMULATOR !== "true" && process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
    Sentry.init({
      Vue,
      environment: "production",
      dsn: "https://64cbb4946dc04ca48cec06189f375d34@o1057148.ingest.sentry.io/6043801",

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      logErrors: true,
    });
  } else {
    Sentry.init({
      Vue,
      environment: "production",
      dsn: "https://64cbb4946dc04ca48cec06189f375d34@o1057148.ingest.sentry.io/6043801",

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      logErrors: true,
    });
  }
  console.log("Sentry initialized");
};

// Load non-critical plugins
const loadNonCriticalPlugins = async () => {
  const VueMask = await import("v-mask");
  const VueFacebookPixel = await import("vue-facebook-pixel");
  const VueSignaturePad = await import("vue-signature-pad");

  // Add FontAwesome to non-critical plugins
  const FontAwesome = await import("@fortawesome/vue-fontawesome");
  const fontAwesomeCore = await import("@fortawesome/fontawesome-svg-core");
  await import("./design/custom/plugins/icons/iconConfig");

  Vue.component("font-awesome-icon", FontAwesome.FontAwesomeIcon);
  fontAwesomeCore.dom.watch();

  Vue.use(VueMask.default);
  Vue.use(VueSignaturePad.default);
  Vue.use(VueFacebookPixel.default, { router });

  console.log("Non-critical plugins loaded");
};

// Initialize non-critical services and plugins
Promise.all([initNonCriticalServices(), loadNonCriticalPlugins()]).then(() => {
  console.log("All app features initialized");
});

// Handle login/logout event from existing tabs
// window.addEventListener("storage", (event) => {
//   if (event.storageArea != localStorage) return;
//   if (
//     event.key === "auth.currentUser" &&
//     (event.newValue === "null" || event.newValue === null || event.oldValue === "null" || event.oldValue === null)
//   ) {
//     console.log("detected auth event from existing tabs");
//     // force reload the page to make the effects take place immediately
//     window.location.reload();
//   }
// });

global.vm = app;
