export default {
  myDetails: "My Details",
  fullName: "Full Name",
  noNameFound: "No Name Found",
  simplifyClaimAddName: "Simplify future claims by adding a name to your account.",
  addName: "Add Name",
  contactDetails: "Contact Details",
  address: "Address",
  cancel: "Cancel",
  save: "Save",
  noAddressFound: "No Address Found",
  simplifyClaimAddAddress: "Simplify future claims by adding an address to your account.",
  communicationPreferences: "Communication Preferences",
  emailReminders: "Email Reminders",
  receiveEmailReminders: "Receive email reminders for your claims",
  smsReminders: "SMS Reminders",
  receiveSmsReminders: "Receive SMS reminders for your claims",
  settinsUpdated: "Settings Updated",
  preferencesUpdated: "Your communication preferences have been updated",
  withdrawClaim: {
    title: "Withdraw Claim",
    text: "Are you sure you want to withdraw your claim? This can not be reversed.",
    cancel: "No. Keep it",
    confirm: "Yes. Withdraw",
  },
};
